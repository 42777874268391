
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import SearchBar from '@/components/search-bars/SearchBar.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { ElNotification } from 'element-plus';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import Multiselect from '@vueform/multiselect';
import axios from 'axios';
export default defineComponent({
    mixins: [apiEndpoint],
    name: 'trainer-list',
    components: {
        Form,
        Datatable,
        SearchBar,
        Swal,
        Multiselect,
    },
    data() {
        return {
            api_url: '',
            load: false,
            loading: false,
            tranche: [] as any,
            association: [] as any,
            btnCheck: false,
            tableHeader: [
                {
                    name: 'Actions',
                    key: 'actions',
                    sortable: false,
                    width: '200px',
                },
                {
                    name: 'Unique Course',
                    key: 'activity_report',
                    sortable: true,
                },
                {
                    name: 'Code',
                    key: 'code',
                    sortable: true,
                },
                {
                    name: 'Name of industry Association / Govt. Org',
                    key: 'association',
                    sortable: true,
                    width: '300px',
                },
                {
                    name: 'Course Found',
                    key: 'course',
                    sortable: true,
                },
            ],
            tranche_id: [] as any,
            courseData: [] as any,
            optionsTranche:[] as any,
            optionsTPartner: [] as any,
            entity_id: [] as any,
            course_type: '' as any,
            start_date: '' as any,
            end_date: '' as any,
            showActivityReport: false,
            componentKey: 0,
            actionActive: false,
            activityReport: [] as any,
            selectAll: {
                id: 'all',
                label: 'All',
            } as any,
            TotalTrainee: 0 as any,
            Totalenroll: '' as any,
            TotalFemaleEnroll: '' as any,
            TotalAssessed: '' as any,
            TotalFemaleAssessed: '' as any,
            Totalcertified: '' as any,
            TotalFemaleCertified: '' as any,
            TotalEmployed: '' as any,
            TotalFemaleEmployed: '' as any,
            Totalenrollment: 0 as any,
            Totalfemale: 0 as any,
            Totalcertification: 0 as any,
            TotalcertificationFemale: 0 as any,
            Totalcertification_percentage: 0 as any,
            total_job_placement: 0 as any,
            total_female_job_placement: 0 as any,
            Totaljob_placement_percentage: 0 as any,
            TotalTarget: '' as any
        };
    },
    async created() {
        this.api_url = this.VUE_APP_API_URL;
        await this.getTotcourseReport();
    },
    methods: {
        async printPdf() {
            let formData = new FormData();
            formData.set('entity', this.entity_id);
            formData.set('tranche', this.tranche_id);
            formData.set('type', this.course_type);
            formData.set('start_date', this.start_date);
            formData.set('end_date', this.end_date);
            let data = `${this.VUE_APP_API_URL}/api/report/course_activity_print`;
            axios.post(data, formData).then((response) => {
                //window.open(data, '_blank');
                document.write(response.data);
                window.print();
                location.reload();
                //open the new window and write your HTML to it
            });
        },

        async downloadPdf() {
            ElNotification({
                dangerouslyUseHTMLString: true,
                message:
                    '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
                duration: 0,
            });

            await ApiService.get('course/tot_course_pdf')
                .then((response) => {
                    ElNotification.closeAll();
                    Swal.fire({
                        title: 'Download Successfull!',
                        icon: 'success',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'View/Download PDF',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // redirect to new tab

                            let path = `${this.VUE_APP_API_URL}/${response.data}`;

                            window.open(path, '_blank');
                        }
                    });
                })
                .catch(({ response }) => {
                    ElNotification.closeAll();
                    console.log(response);
                });
        },
        async exportTrainee() {
            ElNotification({
                dangerouslyUseHTMLString: true,
                message:
                    '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
                duration: 0,
            });

            axios.get(
`${this.VUE_APP_API_URL}/api/course/tot_course_export` , { responseType: 'blob',}
                )
                .then((response) => {
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.ms-excel',
                        })
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'ToT_course_export.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    ElNotification.closeAll();
                })
                .catch((response) => {
                    console.log(response);
                    ElNotification.closeAll();
                });
        },

        async courseActivityReport() {
            this.load = true;
            await ApiService.get(
                'course/tot_course?tranche=' +
                this.tranche_id +
                '&entity=' +
                this.entity_id  )
                .then((response) => {
                    this.activityReport = response.data.data.coursedata;
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        async btnCheckStatus() {
            if (this.tranche_id || this.course_type) {
                this.btnCheck = true;
            } else {
                Swal.fire({
                    title: 'Warning',
                    html: 'Tranche and Training Type Need to be Select!',
                    icon: 'warning',
                });
            }
            // else popup
        },
        view(data) {
            // console.log(data);
            this.emitter.emit('course_unique_details', data);
        },

        async getTranche() {
            await ApiService.get('configurations/tranche/list')
                .then((response) => {
                    console.log(response.data.data);
                    this.tranche = response.data.data;
                    this.tranche.forEach((item, index) => {
                        let obj = {
                            value: item.id,
                            label: item.label,
                        };
                        this.optionsTranche.push(obj);
                    });
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        async getTotcourseReport() {
              this.loading = true;
            await ApiService.get('course/tot-course')
                .then((response) => {
                    this.courseData = response.data.data.results;
                    this.Totalenroll = response.data.data.st_enrolled;
                    this.TotalFemaleEnroll = response.data.data.sf_enrolled;
                    this.TotalAssessed = response.data.data.st_assessed;
                    this.TotalFemaleAssessed = response.data.data.sf_assessed;
                     this.Totalcertified = response.data.data.st_certified;
                    this.TotalFemaleCertified = response.data.data.sf_certified;
                     this.TotalEmployed = response.data.data.st_employed;
                    this.TotalFemaleEmployed = response.data.data.sf_employed;
                    this.TotalTarget   = response.data.data.st_target
                    console.log(response);
                   this.loading = false;
                    this.showActivityReport = true;
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },

        
    },
    setup() {
        const AssociationSchema = [];
        return {
            AssociationSchema,
        };
    },
});
